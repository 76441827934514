import React from "react"
import { graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"
import Seo from '../components/seo/seo'
import Header from '../components/header/header'
import Breadcrumb from '../components/breadcrumb/breadcrumb'
import {
  MainAndSideWrapper,
  PartnerContent,
  article,
  intro,
  sponsorGrid,
  PartnerAside,
} from './kennispartners.module.css'
import { GatsbyImage } from "gatsby-plugin-image"
import SideColumn from '../components/side-column/side-column'
import Footer from '../components/footer/footer'
import MediaImage from "../components/media-image/media-image"

const DesksPage = ({ data }) => {
  const desks = data.allContentfulDesk;

  return (
    <Layout>
      <Seo title="Kennispartners" description="Overzicht van de MKB Servicedesk kennispartner" />
      <Header desks={desks} data={{ name: 'Kennispartners' }} backgroundImage={data.file.childImageSharp.gatsbyImageData} />
      <Helmet>
        <link rel="canonical" href="https://www.mkbservicedesk.nl/kennispartners/" />
      </Helmet>
      <main className="main article">
        <div className="container">

          <Breadcrumb article={{ title: 'Kennispartners' }} />

          <div className={MainAndSideWrapper}>

            <section className={PartnerContent}>
              <div className={`${article} clearfix`}>
                <p className={intro}>Met behulp van de aanwezige kennis bij de kennispartner zorgen we voor een mooie mix van content op MKB Servicedesk, die ondernemers direct verder helpt en/of naar de kennispartner leidt. Aan de hand van diverse soorten content nemen we de ondernemer stap voor stap mee in het proces van bewustwording tot daadwerkelijke actie.</p>
                <h3 className={"kennispartner"}>Kennispartners:</h3>
                <div className={sponsorGrid}>
                  {data.allContentfulSponsor.nodes.map((sponsor, index) => {
                    return <a key={sponsor.id} href={sponsor.url} target="_blank" rel="noreferrer">
                      <MediaImage image={sponsor.image} className="logo" />
                    </a>
                  })}
                </div>
              </div>
            </section> {/* PartnerContent */}

            <div className={PartnerAside}>
              <SideColumn showSponsorInfo={true} />
            </div> {/* PartnerAside */}

          </div> {/* MainAndSideWrapper */}

        </div> {/* container */}
      </main>
      <Footer />

    </Layout>
  )
}

export default DesksPage

export const pageQuery = graphql`
  query { 
    file(relativePath: { eq: "kennispartners.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1180)
      }
    }
    allContentfulSponsor(sort: {order: ASC, fields: name}, filter: {image: {id: {ne: null}}, showOnSponsorPage: {eq: true}}) {
      nodes {
        name
        slug
        url
        image {
          gatsbyImageData(layout: CONSTRAINED, width: 200)
        }
      }
    }

    allContentfulDesk(sort: {fields: name}) {
      nodes {
        name
        slug
        legacyId
        sortNavigation
      }
    }
  }
`
